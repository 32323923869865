/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.production) {
    enableProdMode();
}
const DSN_SENTRY = 'https://00b159793361035aa9c9efa171cf9e20@sentry.energiesdemain.org/3';
if (DSN_SENTRY.includes('https')) {
    Sentry.init({
        dsn: DSN_SENTRY,
        release: 'e0b5d113c49f1e040675f21c483c277b235ff956',
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),
            // Registers the Replay integration,
            // which automatically captures Session Replays
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
